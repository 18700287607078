












































































































































import { Component, Prop, Vue } from 'vue-property-decorator';

import { EMAIL_REGEX } from '@/helpers';

import ImagePanel from '@/components/ImagePanel.vue';
import Logo from '@/components/Logo.vue';
import TextInput from '@/components/ui/TextInput.vue';
import BackButton from '@/components/ui/BackButton.vue';

@Component({
  components: {
    ImagePanel,
    Logo,
    TextInput,
    BackButton,
  },
})
export default class Landing extends Vue {
  email = '';
  password = '';
  name = '';
  loading = false;
  awaitingConfirm = false;
  formState: 'login' | 'forgot' | 'new' | 'waiting' = 'login';

  get isEmailValid() {
    return EMAIL_REGEX.test(this.email);
  }

  get isLoginFormValid() {
    const lengthCheck = !!(this.email.length && this.password.length);

    if (!this.isEmailValid) {
      return false;
    }

    return this.isEmailValid && lengthCheck;
  }

  get isSignUpFormValid() {
    return this.isEmailValid && this.password.length >= 6;
  }

  get disableLogin() {
    return this.loading || !this.isLoginFormValid;
  }

  get disableForgot() {
    return this.loading || !this.isEmailValid;
  }

  get disableCreate() {
    return this.loading || !this.isSignUpFormValid;
  }

  /**
   * Handle creating user
   */
  handleCreate() {
    if (this.disableCreate) {
      return;
    }

    return this.create();
  }

  /**
   * Handle submitting forgot password form
   */
  handleForgotPassword() {
    if (this.disableForgot) {
      return;
    }

    return this.forgot();
  }

  /**
   * Handle submitting login form
   */
  handleLogin() {
    if (this.disableLogin) {
      return;
    }

    return this.login();
  }

  userCheck() {
    if (this.$store.getters['USER__AUTHENTICATED']) {
      this.$router.push({ path: '/' });
    }
  }

  mounted() {
    if (this.$route.query['signup']) {
      this.formState = 'new';
    }

    setTimeout(() => {
      this.userCheck();
    }, 500);
  }

  async create() {
    this.loading = true;
    try {
      const creation = await this.$store.dispatch('createUser', {
        email: this.email,
        password: this.password,
      });

      if (creation.success) {
        this.awaitingConfirm = true;
        this.formState = 'waiting';
      } else {
        throw new Error(creation.message);
      }
    } catch (error) {
      this.handleError(error.message);
    }
    this.loading = false;
  }

  async forgot() {
    this.loading = true;

    try {
      await this.$store.dispatch('forgotPassword', { email: this.email });
      this.$store.dispatch('snackbar/pushMessage', {
        message: `Reset link sent!`,
      });
      this.loading = false;
      this.formState = 'login';
    } catch (error) {
      this.handleError(error.message);
    }
  }

  /**
   * Log in the user
   */
  async login(redirect = true, silent = false) {
    this.loading = true;
    try {
      const response = await this.$store.dispatch('login', {
        email: this.email,
        password: this.password,
      });

      if (!response.success) {
        throw new Error('Invalid credentials');
      }

      this.$emit('loginSuccess');
      this.$store.dispatch('snackbar/pushMessage', { message: `Welcome!` });

      if (redirect) {
        this.$router.push('/');
      }
    } catch (error) {
      if (!silent) {
        this.handleError(error.message);
      }
    }

    this.loading = false;
  }

  handleError(error: string) {
    this.$store.dispatch('snackbar/pushError', { message: error });
  }
}
