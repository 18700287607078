








import { Component, Prop, Vue } from 'vue-property-decorator';
import { helpers } from 'vuelidate/lib/validators';

@Component({})
export default class TextInput extends Vue {
  @Prop({ default: 'text' }) type!: string;
  @Prop({ default: '' }) placeholder!: string;
  @Prop({ default: 'Label' }) label!: string;
  @Prop({ default: '' }) value!: string;

  get validations() {
    return {};
  }

  /**
   * input attributes
   */
  get inputAttrs() {
    return {
      class: 'dn-text-input__input',
      ref: 'input',
      type: this.type,
      placeholder: this.placeholder,
      value: this.value,
    };
  }

  /**
   * emit update event
   */
  update() {
    const ref = this.$refs['input'] as HTMLInputElement;
    this.$emit('input', ref.value);
  }
}
